<template>
  <div class="home_class_container">
    <div class="home_title">
      <span>{{typeName}}</span>
    </div>

    <div class="cont_box" :style="{height: newHeight}">
      <div class="card_item" v-for="(item, index) in classesData.slice(0, 8)" :key="index">
        <img class="card_item_img" :src="QiNiuPath + item.baseCourseImage" alt />
        <div class="card_item_txt">
          <h4>{{item.baseCourseName}}</h4>
          <p>{{item.baseCourseDesc}}</p>
        </div>
        <button class="hover_btn" @click="watchVideo(index)">
          <img class="hover_btn_img" src="../../assets/imgs/video_small_icon.png" alt />
          <span class="hover_btn_txt">查看课程</span>
        </button>
      </div>
      <!-- 背景点动画 -->
      <div class="lab_bg_ani">
        <i class="ant_dot" style="left: 380px; top: 320px"></i>
        <i class="ant_dot" style="left: 790px; top: 100px"></i>
        <i class="ant_dot" style="left: 820px; top: 360px"></i>
      </div>
    </div>

    <VideoPop :showVideo="showVideo" :fileUrl="fileUrl" @closeVideo="closeVideo"></VideoPop>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VideoPop from "@/components/common/videoPop";

export default {
  data() {
    return {
      showVideo: false, // 视频弹窗
      fileUrl: "",
    };
  },
  props: {
    typeName: String,
    classesData: Array,
  },
  computed: {
    newHeight() {
      if(this.classesData.length <= 3) {
        return '440px'
      }
      if(this.classesData.length <= 6) {
        return '650px'
      }
      if(this.classesData.length <= 8) {
        return '792px'
      }
    },
    ...mapState({
      QiNiuPath: (state) => state.indexStore.QiNiuPath,
    }),
  },
  components: {
    VideoPop,
  },
  methods: {
    watchVideo(index) {
      let fileUrl = this.QiNiuPath + this.classesData[index].baseCourseVideo;
      // console.log(fileUrl)
      this.fileUrl = fileUrl;
      this.showVideo = true;
    },
    closeVideo() {
      this.showVideo = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/mixin.scss";

.home_class_container {
  margin: 0px 0 54px 0;
}
.home_title {
  @include sc(40px);
  font-weight: bold;
  text-align: center;
  margin: 0 auto 50px auto;
  width: 1200px;
  position: relative;
  &::after {
    content: "";
    display: block;
    @include wh(70px, 3px);
    background: #5d5aed;
    margin: 7px auto 0 auto;
  }
}
.cont_box {
  // @include wh(1200px, 792px);
  // height: 650px;
  width: 1200px;
  position: relative;
  margin: 0 auto;
  background: url("../../assets/imgs/class_bg.png") no-repeat top;
  background-size: cover;

  .card_item {
    width: 340px;
    height: 200px;
    background: #fff;
    // border: 1px solid red;
    position: absolute;
    transition: all 0.4s ease;
    // cursor: pointer;
    z-index: 1;
    box-shadow: 0px 3px 14px 0px rgba(115, 118, 235, 0.25);
    border-radius: 10px;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
    .card_item_img {
      @include wh(72px, 72px, 10px);
      margin-bottom: 14px;
    }
    .card_item_txt{
      padding:0 6px;
    }
    h4 {
      @include sc(20px, #606978);
      font-weight: bold;
      margin-bottom: 5px;
      transition: all 0.2s ease;
      @include txtflow();
    }
    p {
      @include sc(14px, #9aa7b9);
      transition: all 0.2s ease;
      @include mtxtflow();
      overflow: hidden;
      height: 42px;
    }
    .hover_btn {
      @include wh(102px, 32px, 16px);
      @include sc(14px, #5d5aed);
      position: absolute;
      top: 26px;
      right: 20px;
      visibility: hidden;
      transition: all 0.1s ease;
      background: #fff;
      .hover_btn_img {
        @include wh(18px, 15px);
        margin-right: 4px;
      }
    }
  }
  .card_item:first-of-type {
    top: 41px;
    left: 40px;
  }
  .card_item:nth-of-type(2) {
    top: 159px;
    right: 0;
    margin: 0 auto;
    left: 0;
  }
  .card_item:nth-of-type(3) {
    top: 41px;
    right: 40px;
  }
  .card_item:nth-of-type(4) {
    left: 0;
    top: 280px;
  }
  .card_item:nth-of-type(5) {
    top: 397px;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
  .card_item:nth-of-type(6) {
    right: 0px;
    top: 280px;
  }
  .card_item:nth-of-type(7) {
    top: 520px;
    left: 40px;
  }
  .card_item:nth-of-type(8) {
    top: 520px;
    right: 40px;
  }
  // .card_item:nth-of-type(8){
  // 	right: 40px;
  // }
  // .card_item:first-of-type{
  // 	left: 399px;
  // }
  // .card_item:nth-of-type(3){
  // 	// left: 40px;
  // 	left: 399px;
  // }
  // .card_item:nth-of-type(4){
  // 	top: 159px;
  // 	right: 0;
  // 	margin: 0 auto;
  // 	left: 0;
  // }
  // .card_item:nth-of-type(5){
  // 	top: 397px;
  // 	right: 0;
  // 	margin: 0 auto;
  // 	left: 0;
  // }
  // .card_item:nth-of-type(6){
  // 	right: 40px;
  // }
  // .card_item:nth-of-type(7){
  // 	right: 0;
  // 	left: auto;
  // }
  // .card_item:nth-of-type(8){
  // 	right: 40px;
  // }
  // .card_item:nth-of-type(5n+1){
  // 	top: 41px;
  // }
  // .card_item:nth-of-type(5n+2){
  // 	left: 359px;
  // 	top: 280px;
  // }
  // .card_item:nth-of-type(5n+3){
  // 	top: 520px;
  // }

  .card_item:hover {
    background: #5d5aed;
    color: #fff !important;
    transform: translateY(-10px);
  }
  .card_item:hover .hover_btn {
    visibility: visible;
  }
  .hover_btn:hover {
    transform: scale(1.1);
  }
  .card_item:hover h4 {
    color: #fff;
  }
  .card_item:hover p {
    color: #fff;
  }

  .lab_bg_ani {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .ant_dot {
      width: 8px;
      height: 8px;
      background: rgba(116, 101, 255, 0.5);
      border-radius: 100px;
      position: absolute;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        right: 50%;
        top: 4px;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;
        transform-origin: center center;
        border-radius: 500px;
        border: 1px solid #7465ff;
        animation: scale-out 1.5s linear infinite;
      }
    }
  }
}

@-webkit-keyframes scale-out {
  0% {
    transform: translate(-50%, -50%) scale(0);
    border-color: #7465ff;
  }

  70% {
    transform: translate(-50%, -50%) scale(0.7);
    border-color: rgba(116, 101, 255, 0.8);
  }

  to {
    transform: translate(-50%, -50%) scale(1);
    border-color: rgba(116, 101, 255, 0);
  }
}

@keyframes scale-out {
  0% {
    transform: translate(-50%, -50%) scale(0);
    border-color: #7465ff;
  }

  70% {
    transform: translate(-50%, -50%) scale(0.7);
    border-color: rgba(116, 101, 255, 0.8);
  }

  to {
    transform: translate(-50%, -50%) scale(1);
    border-color: rgba(116, 101, 255, 0);
  }
}
</style>
