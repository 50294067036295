<template>
	<div class="home a-fade-in">
		<a-carousel arrows autoplay v-if="bannerList&&bannerList.length>0">
			<div slot="prevArrow" class="custom-slick-arrow" style="left: 10px;zIndex: 1">
				<a-icon type="left" />
			</div>
			<div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
				<a-icon type="right" />
			</div>
			<div class="slider_div" v-for="(item,b_index) in bannerList" :key="b_index" @click="goBannerDetail(item.accessUrl)">
				<img :src="QiNiuPath+item.bannerUrl" :style="{cursor:item.accessUrl!=null&&item.accessUrl!=''?'pointer ':'default'}" />
			</div>
		</a-carousel>
		<div class="banner" v-else>
			<img src="../assets/imgs/kv.png" />
		</div>
		<!-- <div id="components-layout-demo-basic" v-loading="true"> -->
		<div id="components-layout-demo-basic">
			<!-- 与banner固定距离 -->
			<div class="top_distance"></div>
			<div v-for="(home, index) in homeTypes" :key="index">
				<HomeClass 
					v-if="home.columnTypeName == '基地课程' && home.columnList.length" 
					:classesData="home.columnList" 
					:typeName="home.columnName"
				></HomeClass>
				<HomeGame 
					v-if="home.columnTypeName == '赛事精选' && home.columnList.length" 
					:activeData="home.columnList" 
					:typeName="home.columnName"
				></HomeGame>
				<HomeNews 
					v-if="home.columnTypeName == '新闻资讯' && home.columnList.length" 
					:newsBannerList="home.specialColumnList" 
					:newsList="home.columnList" 
					:typeName="home.columnName"
				></HomeNews>
				<HomeCooperation 
					v-if="home.columnTypeName == '合作伙伴' && home.columnList.length" 
					:coperData="home.columnList" 
					:typeName="home.columnName"
				></HomeCooperation>
			</div>
			

			
			<!-- <div class="bottom_distance"></div> -->
			<!-- <a-layout>
        <a-layout-content id="Content">
          <div v-for="domItem in homeModelList" :key="domItem.sort">
             <div v-if="domItem.typeName=='course'&&domItem.columnList!=null&&domItem.columnList.length>0">
                <h-course-cont :courseRecommend="domItem" />
             </div>
             <div v-else-if="domItem.typeName=='news'&&domItem.columnList!=null&&domItem.columnList.length>0">
                <h-news :newsInformation="domItem"/>
             </div>
             <div v-else-if="domItem.typeName=='event'&&domItem.columnList!=null&&domItem.columnList.length>0">
                <h-event-center :matchSelection="domItem"/>
             </div>
          </div>
        </a-layout-content>
      </a-layout>-->
		</div>
	</div>
</template>

<script>
	import {mapState,mapMutations} from "vuex";
	import {queryIndexList,queryBannerList, queryHomePageColumn} from "@/apis/Home.js";
	import {queryNewsPage} from "@/apis/News.js";

	//引入首页模块
	// import CourseCont from "./components/CourseCont.vue";
	// import EventCenter from "./components/EventCenter.vue";
	// import News from "./components/News.vue";
	import HomeClass from "../components/home/classes";
	import HomeGame from "../components/home/game";
	import HomeNews from "../components/home/news";
	import HomeCooperation from "../components/home/cooperation";
	import VideoPop from '@/components/common/videoPop';


	export default {
		name: "Home",
		data() {
			return {
				courseRecommend: null,
				matchSelection: null,
				newsInformation: null,
				bannerList: [],
				homeModelList: [],
				newData: {
					name: "123",
					age: "456",
				},

				newsBannerList: [], //新闻轮播
				newsList: [], // 新闻列表
				homeConfig: {}, //导航列表
				homeTypes: [], //
			};
		},
		computed: {
			...mapState({
				QNPath: (state) => state.indexStore.QNPath,
				QiNiuPath: (state) => state.indexStore.QiNiuPath,
				KeyWord: (state) => state.indexStore.KeyWord,
			}),
		},
		components: {
			// HCourseCont: CourseCont,
			// HEventCenter: EventCenter,
			// HNews: News,
			HomeClass,
			HomeGame,
			HomeNews,
			HomeCooperation,
			VideoPop,
		},
		created() {
			// this.getIndexList();
			this.getBannerList();
			this.getHomeTypes();
		},
		methods: {
			// getIndexList() {
			// 	let that = this;
			// 	let params = {};
			// 	queryIndexList(params).then((res) => {
			// 		let msg =
			// 			res != undefined && res.data != undefined && res.data != null ?
			// 			res.data :
			// 			null;
			// 		if (msg != null && msg.status == 0 && msg.result != null) {
			// 			let tempList = [{}, {}, {}];
			// 			let homeData = msg.result;
			// 			if (homeData.courseRecommend != null) {
			// 				let courseRecommend = {
			// 					typeName: "course",
			// 					...homeData.courseRecommend,
			// 				};
			// 				tempList[homeData.courseRecommend.sort - 1] = courseRecommend;
			// 			}
			// 			if (homeData.matchSelection != null) {
			// 				let matchSelection = {
			// 					typeName: "event",
			// 					...homeData.matchSelection,
			// 				};
			// 				tempList[homeData.matchSelection.sort - 1] = matchSelection;
			// 			}
			// 			if (homeData.newsInformation != null) {
			// 				let newsInformation = {
			// 					typeName: "news",
			// 					...homeData.newsInformation,
			// 				};
			// 				tempList[homeData.newsInformation.sort - 1] = newsInformation;
			// 			}
			// 			that.homeModelList = tempList;
			// 		}
			// 	});
			// },
			getBannerList: function() {
				let that = this;
				let params = {
					currentPage: that.matchCurrentPage,
					pageSize: that.matchPageSize,
				};
				that.$nextTick(() => {
					queryBannerList(params).then((res) => {
						let msg = res.data != undefined && res.data != null ? res.data : null;
						if (msg != null && msg.status == 0) {
							that.bannerList = msg.result ? [...msg.result] : [];
						}
					});
				});
			},
			goBannerDetail(bannerDetailUrl) {
				if (bannerDetailUrl != null && bannerDetailUrl != "") {
					window.open(bannerDetailUrl);
				}
			},

			// 获取首页分类列表
			getHomeTypes() {
				queryHomePageColumn({ }).then((res) => {
					if (res.data.status == 0) {
						let back = res.data.result
						this.homeTypes = back
					}
				});
			},
		},
	};
</script>
<style scoped>
	@import "./home.css";

	.top_distance {
		margin-top: 45px;
	}

	.bottom_distance {
		margin-bottom: 164px;
	}
	.ant-carousel{
		min-width: 1200px;
	}
	.ant-carousel>>>.slick-slide {
		text-align: center;
		/* height: 480px;
		line-height: 480px; */
		/* background: #364d79; */
		overflow: hidden;
		margin-bottom: -5px;;
		/* transition: all .2s ease; */
	}

	.ant-carousel>>>.custom-slick-arrow {
		display: none;
	}

	.ant-carousel>>>.custom-slick-arrow:before {
		display: none;
	}

	.ant-carousel>>>.custom-slick-arrow:hover {
		display: none;
	}

	.slick-active button {
		width: 36px;
		height: 11px;
		background: rgba(93, 90, 237, 0.8);
		border-radius: 6px;
	}

	.ant-carousel .slick-slide img {
		display: block;
		width: 100%;
		/* height: 480px ; */
		/* transition: all .2s ease; */
	}

	.ant-layout {
		background-color: unset;
	}


	.anticon-left {
		font-size: 20px;
		color: #ffffff;
		line-height: 39px;
		width: 35px;
		height: 35px;
		background: rgba(28, 34, 46, 1);
		border-radius: 0px 12px 12px 0px;
		opacity: 0.6;
		text-align: center;
	}

	.anticon-right {
		font-size: 20px;
		color: #ffffff;
		line-height: 39px;
		width: 35px;
		height: 35px;
		background: rgba(28, 34, 46, 1);
		border-radius: 12px 0px 0px 12px;
		opacity: 0.6;
		text-align: center;
		margin-left: -20px;
	}

	.custom-slick-arrow {
		display: none !important;
		transition: all 0.3s ease;
	}

	.home .ant-carousel:hover .custom-slick-arrow {
		display: block !important;
	}

	.banner {
		width: 100%;
		height: auto;
	}

	.banner img {
		width: 100%;
	}

	@media screen and (max-width: 1200px) {
		.ant-carousel>>>.slick-slide {
			height: 400px;
		}

		.ant-carousel .slick-slide img {
			height: 400px;
		}
	}

	@media screen and (max-width: 1080px) {
		.ant-carousel>>>.slick-slide {
			height: 300px;
		}

		.ant-carousel .slick-slide img {
			height: 300px;
		}
	}
	.slider_div{
		/* 防止轮播图图片抖动 */
		transform: translateZ(0);
	}
	.a-fade-in{
		animation: fade-in 0.4s linear;
	}
	.slider_fade_in{
		animation: fade-in 0.2s linear;
	}

	@keyframes fade-in{
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	/* 首页 */

	/* #Content>h2{
	font-size: 40px;
	text-align: center;
	color: #333A48;
	width: 1200px;
	margin: 0 auto;
	margin-top: 60px;
	}
	.RecomCourses{
	margin:0 auto;
	width: 1381px;
	height: 425px;
	background:url(../assets/imgs/hot_bg.png)center no-repeat;
	position: relative;
	}
	.RecomCourses .title{
	position: absolute;
	top: 0;
	left: 0;
	margin-top:150px;
	color: #fff;  
	width: 500px;
	margin-left: 700px;
	}
	.RecomCourses .title>h3{
	font-size: 42px;
	line-height: 56px;
	color: #fff; 
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-line-clamp:2;
	overflow:hidden;
	}
	.RecomCourses .title>p{
	color: #fff; 
	font-size:28px;
	cursor: pointer;
	margin-top: 40px;
	}
	.RecomCourses .title>p>img{
	vertical-align: -6px;
	margin-left: 10px;
	}
	#Main{
	width: 1200px;
	height: auto;
	overflow-y: hidden;
	margin:0 auto; 
	}
	.Listcolumn{
	width: 100%;
	margin-top:60px;
	}
	.Listcolumn>ul{
	width: 100%;
	height: auto;
	overflow-y: hidden;
	}
	.Listcolumn>ul>li{
	margin-left: 28px;
	float: left;
	cursor: pointer;
	}
	.Listcolumn .panel{
	width:382px;
	height:236px;
	padding:20px;
	background-color: #FFFFFF;
		border-radius: 8px;
		-moz-box-shadow:3px 5px 14px 0px #E9E9EA;
		-webkit-box-shadow:3px 5px 14px 0px #E9E9EA;
		box-shadow:3px 5px 14px 0px #E9E9EA;
	margin: 10px 0px;
	cursor: pointer;
	float: left;
	position: relative;
	}
	.Listcolumn .panel>h2{
	font-size:30px;
	color: #333A48;
	letter-spacing: 1px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	}
	.Listcolumn .panel>h2>span{
	background:url(../assets/imgs/grade.png)left no-repeat;
	text-align: center;
	font-size: 16px;
	color: #fff;
	padding: 13px 12px;
	margin-right: 5px;
	vertical-align: 4px;
	}
	.Listcolumn .panel>h3{
	color:#9AA7B9;
	font-size: 14px;
	margin-top: 5px;
	}
	.Listcolumn .teacher{
	margin-top: 30px;
	}
	.Listcolumn .teacher>img{
	width: 36px;
	height: 36px;
	border-radius: 100%;
	float: left;
	margin-right: 8px;
	}
	.Listcolumn .teacher>li{
	float: left;
	}
	.Listcolumn .teacher>h2{
	font-size: 13px;
	color: #606978;
	margin-left: 10px;
	float: left;
	}
	.Listcolumn .teacher>h2>span{
	color: #9AA7B9;
	font-size: 12px;
	}
	.Listcolumn li:hover .Mouselayer{
	display: block;
	}
	.Listcolumn li:hover .panel{
	display: none;
	}
	.headgroup{
	width: 100px;
	height: auto;
	overflow-y: hidden;
	float: left;
	position: relative;
	margin-left: -10px;
	}
	.headgroup>img {
	margin-left: -10px;
	width: 36px;
	height: 36px;
	border-radius: 100%;
	}
	.head3 {
	position: absolute;
	width: 36px;
	height: 36px;
	background-color: #00000061;
	border-radius: 50%;
	z-index: 100;
	text-align: center;
	color: #fff;
	font-size: 20px;
	line-height: 24px;
	right: 0;
	top: 0;
	margin-right:9px;
	}
	.Listcolumn .panel>p{
	position:absolute;
	bottom: 20px;
	left: 20px;
	font-size: 14px;
	color: #606978;
	}
	.Mouselayer{
	background:url(../assets/imgs/mouse_bg.png)left no-repeat;
	width: 382px;
	height: 236px;
	display: none;
	margin: 10px 0px;
	color: #fff;
	padding: 20px;
	position: relative;
	}
	.Mouselayer>h2{
	font-size:30px;
	color: #fff;
	margin-top: 5px;
	letter-spacing: 1px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	}
	.Mouselayer>h2>span{
	background:url(../assets/imgs/grade2.png)left no-repeat;
	text-align: center;
	font-size: 16px;
	color: #fff;
	padding: 13px 12px;
	margin-right: 5px;
	vertical-align: 4px;
	}
	#circle {
	width: 12px;
	height: 12px;
	border: 1px #fff solid;
	-webkit-border-radius: 100px;
	float: left;
	margin-top: 8px;
	margin-right: 10px;
	clear: both;
	}
	.Mouselayer .depict{
	position:absolute;
	bottom: 10px;
	left: 20px;
	font-size:16px;
	}
	.Mouselayer .depict>p{
	margin-bottom: 5px !important;
	}
	.Mouselayer button{
	width:120px;
	height:40px;
	background:rgba(255,255,255,1);
	box-shadow:0px 4px 10px 0px rgba(0,0,0,0.1);
	border-radius:25px;
	font-size: 16PX;
	color: #5D5AED;
	line-height: 40px;
	text-align: center;
	border: none;
	outline: unset;
	cursor: pointer;
	position: absolute;
	right: 20px;
	bottom: 20px;
	}
	#Content>h2>span{
	font-size: 16px;
	color: #606978;
	float:right;
	line-height: 60px;
	cursor: pointer;
	}
	#Content>h2>span>img{
	vertical-align: -4px;
	margin-left: 8px;
	}
	#Main .EventHigh{
	padding: 15px;
	margin-top: 40px;
	}
	.EventHigh>ul{
	overflow-y: hidden;
	padding: 20px;
	height: auto;
	background:rgba(255,255,255,1);
	box-shadow:3px 5px 14px 0px #E9E9EA;
	border-radius:6px;
	margin-bottom: 30px;
	cursor: pointer;
	}
	.EventHigh>ul:hover{
	box-shadow:3px 5px 14px 0px rgba(0,0,0,0.2);
	}
	.EventHigh>ul>li{
	float: left;
	}
	.EventHigh>ul>li>img{
	width: 304px;
	height: 209px;
	}
	.EventHigh .list{
	margin-left: 50px;
	width: 760px;
	position: relative;
	height: 213px;
	}
	.EventHigh .list>h2{
	font-size: 24px;
	color: #333A48;
	letter-spacing: 1px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	}
	.EventHigh .list>h2>img{
	vertical-align: -2px;
	margin-left: 10px;
	}
	.EventHigh .list>p{
	color: #9AA7B9;
	font-size: 16px;
	line-height:30px;
	margin-top: 15px;
	} 
	.EventHigh .list>h3{
	color: #9AA7B9;
	font-size: 16px;
	position:absolute;
	bottom: 0;
	left: 0;
	} 
	.EventHigh .list>h3>img{
	width: 20px;
	height: 20px;
	margin-right: 8px;
	vertical-align: -3px;
	}
	#Main .news{
	width: 100%;
	margin-top: 40px;
	padding: 10px;
	}
	.news>ul{
	height: auto;
	overflow-y: hidden;
	width: 100%;
	}
	.news>ul>li{
	float: left;
	width: 560px;
	background-color: #fff;
	cursor: pointer;
	margin-left: 20px;
	padding: 20px;
	border-radius: 2px;
	margin-bottom: 30px;
	position: relative;
	}
	.news>ul>li:hover{
	box-shadow:0px 4px 10px 0px rgba(0,0,0,0.1);
	}
	.news>ul>li>h2{
	color: #333A48;
	line-height: 35px;
	font-size: 22px;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-line-clamp:2;
	overflow:hidden;
	padding-bottom: 20px;
	}
	.news>ul>li>h2>img{
	margin-right: 10px;
	vertical-align: -4px;
	width: 45px;
	}
	.news>ul>li>img{
	width:170px;
	height: 115px;
	float: left;
	}
	.news>ul>li>p{
	float: left;
	width: 320px;
	margin-left: 30px;
	line-height: 30px;
	color: #606978;
	font-size: 16px;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-line-clamp:3;
	overflow:hidden;
	margin-top: -3px;
	}
	.news>ul>li>h3{
	margin-top: 10px;
	font-size: 14px;
	color: #9AA7B9;
	float: left;
	font-weight: 400;
	position: absolute;
	bottom: 16px;
	left: 0;
	margin-left: 220px;
	} */
</style>
