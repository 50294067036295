<template>
  <div class="home_news_container">
    <div class="home_title">
      <span>{{ typeName }}</span>
      <button class="btn_more" @click="goMore">
        查看更多
        <img src="../../assets/imgs/arrow_icon.png" alt />
      </button>
    </div>

    <div class="cont_box">
      <!-- <div class="banner">
        <a-carousel arrows :autoplay="autoPlay" v-if="newsBannerList.length>1">
          <div
            slot="prevArrow"
            slot-scope="props"
            class="custom-slick-arrow"
            style="left: 10px; zIndex: 1"
          >
            <a-icon type="left" />
          </div>
          <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
            <a-icon type="right" />
          </div>
          <div v-for="(item,index) in newsBannerList" :key="index" style="cursor:pointer">
            <img :src="QiNiuPath+item.newsmaxPic" @click="goDetails(item)" />
            <h3>
              <span class="spanNewBannerTitle">{{item.newsTitle}}</span>
            </h3>
          </div>
        </a-carousel>

        <div v-if="newsBannerList.length>0&&newsBannerList.length<2" class="newsbanner">
          <img :src="QiNiuPath+newsBannerList[0].newsmaxPic" @click="goDetails(newsBannerList[0])" />
          <h3>
            <span class="spanNewBannerTitle">{{newsBannerList[0].newsTitle}}</span>
          </h3>
        </div>
      </div> -->

      <div class="news_lists">
        <NewsItem
          v-for="(item, index) in newsList"
          :key="index"
          :newsData="item"
          @toDetail="goDetails"
        ></NewsItem>
        <!-- <div class="news_item" @click="goDetails(item)" v-for="(item, index) in newsList" :key="index">
                    <h2 class="news_title"><img class="news_icon" src="../../assets/imgs/new_icon.png" alt="">{{item.newsTitle}}</h2>
                    <div class="news_desc">
                        <div class="news_desc_left">
                            <img class="news_desc_left_img" :src="QiNiuPath + item.newsPic" alt="">
                        </div>
                        <div class="news_desc_right">
                            <p class="news_txt">{{item.newsIntroduce}}</p>
                            <p class="news_time">{{item.showTime}}</p>
                        </div>
                    </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import NewsItem from "@/components/common/newsitem";

export default {
  data() {
    return {
      autoPlay: true,
    };
  },
  props: {
    typeName: String,
    newsBannerList: Array,
    newsList: Array,
  },
  computed: {
    ...mapState({
      QiNiuPath: (state) => state.indexStore.QiNiuPath,
    }),
  },
  components: {
    NewsItem,
  },
  methods: {
    // 跳转详情
    goDetails(e) {
      if (
        e.sourceUrl != null &&
        e.sourceUrl != undefined &&
        e.sourceUrl.trim() != ""
      ) {
        window.open(e.sourceUrl);
      } else {
        this.$router.push({
          path: "/VNewsDetails",
          query: { NewsId: e.newsId, NewsSource: 0 },
        });
      }
    },
    // 查看更多
    goMore() {
      this.$router.push({ path: "/VNews" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/mixin.scss";
.home_news_container {
  margin-bottom: 54px;
}
.home_title {
  @include sc(40px);
  font-weight: bold;
  text-align: center;
  margin: 0 auto 50px auto;
  width: 1200px;
  position: relative;
  &::after {
    content: "";
    display: block;
    @include wh(70px, 3px);
    background: #5d5aed;
    margin: 7px auto 0 auto;
  }
  .btn_more {
    @include sc(16px, #5d5aed);
    @include wh(114px, 40px, 20px);
    line-height: 40px;
    background: rgba(116, 101, 255, 0.1);
    border: 1px solid rgba(116, 101, 255, 0.5);
    position: absolute;
    right: 0;
    top: 18%;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background: #5d5aed;
      color: #fff;
    }
    img {
      @include wh(18px, 18px);
      // margin-left: 4px;
      vertical-align: -3px;
    }
  }
}

.cont_box {
  width: 1200px;
  margin: 0 auto;
  // padding-top: 30px;

  .news_lists {
    margin-top: 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .news_item {
      @include wh(590px, 220px);
      padding: 20px 20px 10px 20px;
      box-sizing: border-box;
      transition: all 0.4s ease;
      cursor: pointer;
      margin-bottom: 15px;
      &:hover {
        border-radius: 12px;
        box-shadow: 3px 4px 14px 0px rgba(0, 0, 0, 0.15);
        transform: translateY(-4px);
      }
      .news_title {
        @include sc(22px, #333a48);
        font-weight: bold;
        margin-bottom: 30px;
        @include mtxtflow(2);
        .news_icon {
          @include wh(45px, 24px);
          margin-right: 10px;
          vertical-align: -4px;
        }
      }
      .news_desc {
        display: flex;
        height: 114px;
        .news_desc_left {
          // @include wh(168px, 114px);
          img {
            @include wh(168px, 114px, 6px);
            margin-right: 29px;
          }
        }
        .news_desc_right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .news_txt {
            @include sc(16px, #606978);
            @include mtxtflow(3);
          }
          .news_time {
            @include sc(14px, #9aa7b9);
          }
        }
      }
    }
  }
}

.newsbanner {
  width: 1200px;
  height: 420px;
  position: relative;
  cursor: pointer;
}
.newsbanner img {
  width: 100%;
  height: 420px;
}
.newsbanner h3 {
  color: #fff;
  background: rgba(28, 34, 46, 0.5);
  padding-left: 15px;
  width: 100%;
  height: 57px;
  line-height: 57px;
  text-align: left;
  position: absolute;
  bottom: 0;
  font-size: 24px;
}
.newsbanner span {
}
[v-cloak] {
  display: none;
}
.ant-carousel ::v-deep .slick-slide {
  text-align: center;
  height: 420px;
  line-height: 420px;
  background: #364d79;
  overflow: hidden;
  /* border-radius: 8px; */
}

.ant-carousel ::v-deep .custom-slick-arrow {
  display: none;
}
.ant-carousel ::v-deep .custom-slick-arrow:before {
  display: none;
}
.ant-carousel ::v-deep .custom-slick-arrow:hover {
  display: none;
}
.ant-carousel .slick-slide img {
  display: block;
  width: 100%;
  height: 420px;
}
.ant-carousel ::v-deep .slick-slide h3 {
  color: #fff;
  background: rgba(28, 34, 46, 0.5);
  /* background:rgba(0,0,0,0.3); */
  /* opacity:0.8;  */
  padding-left: 15px;
  width: 100%;
  height: 57px;
  line-height: 57px;
  text-align: left;
  position: absolute;
  bottom: 0;
  font-size: 24px;
}
.anticon-left {
  font-size: 16px;
  color: #ffffff;
  line-height: 38px;
  width: 35px;
  height: 35px;
  background: rgba(28, 34, 46, 1);
  border-radius: 0px 12px 12px 0px;
  opacity: 0.6;
  text-align: center;
}
.anticon-right {
  font-size: 16px;
  color: #ffffff;
  line-height: 38px;
  width: 35px;
  height: 35px;
  background: rgba(28, 34, 46, 1);
  border-radius: 12px 0px 0px 12px;
  opacity: 0.6;
  text-align: center;
  margin-left: -20px;
}
.custom-slick-arrow {
  display: none !important;
}
.banner {
  border-radius: 8px;
  overflow: hidden;
}
.banner:hover .custom-slick-arrow {
  display: block !important;
}

.spanNewBannerTitle {
  max-width: 700px;
  display: inline-block;
  // float: left;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
}
</style>
