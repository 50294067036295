<template>
  <div class="home_game_container">
    <div class="home_title">
      <span>{{ typeName }}</span>
      <button class="btn_more" @click="goMore">
        查看更多
        <img src="../../assets/imgs/arrow_icon.png" alt />
      </button>
    </div>

    <div class="cont_box">
      <ActiveItem
        v-for="(item, index) in activeData.slice(0, 6)"
        :key="index"
        :activeData="item"
        @toDetail="goMatchDetail"
        @toDetailt="goMatchDetailt"
        class="a-fade-in"
      ></ActiveItem>
    </div>
  </div>
</template>

<script>
import ActiveItem from "@/components/common/activeitem";
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      PortalConfig: (state) => state.indexStore.PortalConfig,
      MODELCODE: (state) => state.indexStore.MODELCODE,
    }),
  },
  props: {
    typeName: String,
    activeData: Array,
  },
  components: {
    ActiveItem,
  },
  methods: {
    goMore() {
      this.$router.push({
        path: "/VEvent_Center",
      });
    },

    goMatchDetail(url) {
      window.open(url);
    },
    goMatchDetailt (pclive) {
      console.log(111111111222)
      window.open(pclive);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/mixin.scss";

.home_game_container {
  margin-bottom: 54px;
}
.home_title {
  @include sc(40px);
  font-weight: bold;
  text-align: center;
  margin: 0 auto 50px auto;
  width: 1200px;
  position: relative;
  &::after {
    content: "";
    display: block;
    @include wh(70px, 3px);
    background: #5d5aed;
    margin: 7px auto 0 auto;
  }
  .btn_more {
    @include sc(16px, #5d5aed);
    @include wh(114px, 40px, 20px);
    background: rgba(116, 101, 255, 0.1);
    border: 1px solid rgba(116, 101, 255, 0.5);
    position: absolute;
    right: 0;
    top: 18%;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background: #5d5aed;
      color: #fff;
    }
    img {
      @include wh(18px, 18px);
      // margin-left: 4px;
      vertical-align: -3px;
    }
  }
}

.cont_box {
  width: 1200px;
  margin: 0 auto 0 auto;
  // @include fj();
  // flex-flow: row wrap;
  .card_item {
    @include wh(580px, 584px, 10px);
    overflow: hidden;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin-bottom: 36px;
    transition: all 0.4s ease;
    &:hover .card_item_img {
      transform: scale(1.1);
    }
    .cover_box {
      @include wh(100%, 386px);
      overflow: hidden;
    }
    .card_item_img {
      @include wh(100%, 386px);
      transition: all 0.3s ease-out;
    }
    .card_infro {
      padding: 15px 20px 24px 20px;
      box-sizing: border-box;
      height: 198px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: all 0.4s ease-out;
      .card_item_title {
        @include sc(24px, #1c222e);
        font-weight: bold;
        @include fa();
        // justify-content: space-between;
        margin-bottom: 12px;
        .card_label {
          @include wh(60px, 26px);
          margin-left: 10px;
        }
      }
      .card_desc {
        @include sc(16px, #9aa7b9);
        // margin-bottom: 18px;
      }
      .card_item_time {
        @include sc(16px, #9aa7b9);
        @include fa();
        .time_icon {
          @include wh(24px, 24px);
          margin-right: 6px;
        }
      }
    }
  }
}
</style>
