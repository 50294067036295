<template>
  <div class="home_cooperation_container">
    <div class="home_title">
      <span>{{ typeName }}</span>
    </div>

    <div class="cont_box">
      <div class="swiper_out">
        <swiper :options="swiperOption" class="swiper" ref="mySwiper">
          <swiper-slide v-for="(item, index) in coperData" :key="index">
            <div
              class="slide_item"
              @mouseover="swiperOver"
              @mouseout="swiperOut"
            >
              <img :src="QiNiuPath + item.cooperativePartnerImageUrl" alt />
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      swiperOption: {
        // autoplay: {
        //   disableOnInteraction: false,
        //   delay: 3000,
        // },
        slidesPerView: 5,
        spaceBetween: 20,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  props: {
    typeName: String,
    coperData: Array,
  },
  mounted() {},
  components: {},
  computed: {
    ...mapState({
      QiNiuPath: (state) => state.indexStore.QiNiuPath,
    }),

    mySwiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    //划入轮播卡片
    swiperOver() {
      this.mySwiper.autoplay.stop();
    },
    //划出轮播卡片
    swiperOut() {
      this.mySwiper.autoplay.start();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/mixin.scss";

.home_cooperation_container {
  padding: 40px 0 164px 0;
  background: #f8f9fa;
  min-width: 1200px;
}
.home_title {
  @include sc(40px);
  font-weight: bold;
  text-align: center;
  margin: 0 auto 50px auto;
  width: 1200px;
  position: relative;
  &::after {
    content: "";
    display: block;
    @include wh(70px, 3px);
    background: #5d5aed;
    margin: 7px auto 0 auto;
  }
}

.cont_box {
  width: 1200px;
  margin: auto;
  .slide_item {
    overflow: hidden;
    border-radius: 10px;
    transition: all 0.3s ease;
    background: #fff;
    height: 160px;
    text-align: center;
    line-height: 160px;
    img {
      height: 100%;
      transition: all 0.3s ease;
    }
    &:hover {
      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
    }
    &:hover img {
      transform: scale(1.1);
      // transform: translateY(-4px);
    }
  }
}
.swiper_out {
  position: relative;
}
.swiper-container {
  height: 180px;
}
.swiper-button-next,
.swiper-button-prev {
  // --swiper-theme-color: #ff6600;/* 设置Swiper风格 */
  --swiper-navigation-color: #fff; /* 单独设置按钮颜色 */
  --swiper-navigation-size: 15px; /* 设置按钮大小 */
  width: 30px;
  height: 50px;
  background: linear-gradient(
    180deg,
    rgba(156, 146, 255, 1) 0%,
    rgba(103, 92, 208, 1) 100%
  );
  border-radius: 4px;
  position: absolute;
  top: 30%;
  // opacity: 0.3;
  transition: all 0.2s ease;
  &:hover {
    opacity: 1;
    transform: translateY(-4px);
  }
}
.swiper-button-next {
  right: -50px;
}
.swiper-button-prev {
  left: -50px;
}
</style>
